import Splide from '@splidejs/splide';

const splide = new Splide('.splide', {
    perPage: 1,
    type: 'slide',
    autoHeight: true
})

splide.mount();


const throttle = (callee, timeout) => {
    let timer = null
    return function perform(...args) {
        if (timer) return

        timer = setTimeout(() => {
            callee(...args)

            clearTimeout(timer)
            timer = null
        }, timeout)
    }
}


/**
 * Скролл
 */
const header = document.querySelector('header');
const navLinks = document.querySelectorAll('nav a');
const sections = document.querySelectorAll('section');

const setActiveLink = () => {
    let current = '';

    sections.forEach((section) => {
        const sectionTop = section.offsetTop;
        if (window.pageYOffset >= sectionTop - header.offsetHeight) {
            current = section.getAttribute('id');
        }
    });

    navLinks.forEach((link) => {
        link.classList.remove('active');

        const id = link.getAttribute('href').replace('#', '');

        if (id === current) {
            link.classList.add('active');
        }
    });
}

setActiveLink()

document.addEventListener('scroll', throttle(setActiveLink, 150));

navLinks.forEach((link) => {
    link.addEventListener('click', (e) => {
        e.preventDefault();

        const id = link.getAttribute('href');

        const yOffset = -header.offsetHeight;

        const element = document.querySelector(id);

        const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

        window.scrollTo({top: y, behavior: 'smooth'});
    })
})

/**
 * Видео
 * @type {HTMLElement}
 */

const videoWho = document.getElementById('video-who');
const videoWhoPlay = document.getElementById('video-who-play');

videoWhoPlay.addEventListener('click', () => {
    videoWho.classList.add('z-20')
    videoWho.play()
});
